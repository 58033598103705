import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(signed-in)": [~19,[2]],
		"/(signed-in)/accept-invite": [20],
		"/(public)/acceptable-use": [13],
		"/(signed-in)/admin": [21,[2,3]],
		"/(signed-in)/admin/organisations": [22,[2,3]],
		"/(signed-in)/admin/organisations/[id]": [23,[2,3]],
		"/(signed-in)/admin/plans": [24,[2,3]],
		"/(signed-in)/admin/plans/new": [26,[2,3]],
		"/(signed-in)/admin/plans/[id]": [25,[2,3]],
		"/(signed-in)/admin/users": [27,[2,3]],
		"/(signed-in)/admin/users/new": [28,[2,3]],
		"/(signed-in)/analytics": [29,[2]],
		"/(signed-in)/conversations": [30,[2]],
		"/(signed-in)/conversations/copilot/[id]": [37,[2]],
		"/(signed-in)/conversations/copilot/[id]/questions": [38,[2]],
		"/(signed-in)/conversations/copilot/[id]/topic": [39,[2]],
		"/(signed-in)/conversations/new": [40,[2]],
		"/(signed-in)/conversations/[id]": [31,[2,4]],
		"/(signed-in)/conversations/[id]/lexicon": [32,[2,4]],
		"/(signed-in)/conversations/[id]/questions": [33,[2,4]],
		"/(signed-in)/conversations/[id]/questions/library": [34,[2,4,5]],
		"/(signed-in)/conversations/[id]/settings": [35,[2,4]],
		"/(signed-in)/conversations/[id]/sharing": [36,[2,4]],
		"/(public)/cookies": [14],
		"/(public)/error": [15],
		"/(signed-out)/forgotten-password": [64,[12]],
		"/(signed-in)/library": [41,[2]],
		"/(signed-in)/onboarding": [42,[6]],
		"/(signed-in)/onboarding/create": [46,[6]],
		"/(signed-in)/onboarding/[id]": [43,[6,7]],
		"/(signed-in)/onboarding/[id]/essentials": [44,[6,7]],
		"/(signed-in)/onboarding/[id]/questions": [45,[6,7]],
		"/(public)/preview/[experience_id]": [~16],
		"/(public)/privacy": [17],
		"/(signed-in)/questions/[id]": [47,[2]],
		"/(signed-in)/questions/[id]/library": [48,[2,8]],
		"/(signed-in)/record/[id]": [49],
		"/(signed-out)/reset-password": [65,[12]],
		"/(signed-in)/search": [50,[2]],
		"/(signed-in)/settings": [51,[2,9]],
		"/(signed-in)/settings/account": [52,[2,9]],
		"/(signed-in)/settings/account/billing/update": [53,[2,10]],
		"/(signed-in)/settings/account/billing/update/checkout": [55,[2,10]],
		"/(signed-in)/settings/account/billing/update/checkout/success": [56,[2,10]],
		"/(signed-in)/settings/account/billing/update/[plan]": [54,[2,10]],
		"/(signed-in)/settings/account/domains": [57,[2,9]],
		"/(signed-in)/settings/account/subscription": [58,[2,9]],
		"/(signed-in)/settings/team": [59,[2,9]],
		"/(signed-in)/settings/user": [60,[2,9]],
		"/(signed-out)/sign-in": [66,[12]],
		"/(signed-out)/sign-up": [67,[12]],
		"/(signed-out)/sign-up/confirm": [68,[12]],
		"/(signed-in)/team": [61,[2]],
		"/(signed-in)/team/invite": [62,[2,11]],
		"/(public)/terms": [18],
		"/(signed-in)/videos/[id]": [63,[2]],
		"/(signed-out)/waitlist": [69,[12]],
		"/(signed-out)/waitlist/confirm": [70,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';